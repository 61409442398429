import { useAuthState } from './firebase'
import { Navbar } from './navbar'
import { useState, useEffect } from 'react'
import { getDatabase, ref, get, onValue, child } from 'firebase/database';

import './css/home.css'

export const Home = () => {
  const { user } = useAuthState();
  const [videoList, setVideoList] = useState([]);
  const [index, setIndex] = useState(1);
  const [activeIndex, setActiveIndex] = useState(1); 
  const [query, setQuery] = useState("");

  useEffect(() => {
    const db = getDatabase();
    const videoRef = ref(db, "videos");
    
    onValue(videoRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        setVideoList(Object.values(data));
      }
    });
  }, []);

  const handleSelectChange = (event) => {
    const selectedIndex = parseInt(event.target.value, 10);
    handleNextClick(selectedIndex);
  };

  function handleNextClick(index) {
    setIndex(index);
    setActiveIndex(index); 
  }

  const listAll = () => {
    if (query === "") {
      return videoList.map((video, index) => (
        <a
          key={index}
          id={index}
          className={activeIndex === index ? 'active' : ''}
          onClick={() => handleNextClick(index)}
        >
          {video.name}
        </a>
      ));
    } else {
      const filteredVideos = videoList.filter(video => {
        if (Array.isArray(video.tags)) {
          return video.tags.some(tag => tag.toLowerCase().includes(query.toLowerCase()));
        } else {
          return false;
        }
      });
      
      return filteredVideos.map((video, index) => (
        <a
          key={index}
          id={index}
          className={activeIndex === videoList.indexOf(video) ? 'active' : ''}
          onClick={() => handleNextClick(videoList.indexOf(video))}
        >
          {video.name}
        </a>
      ));
    }
  };

  return (
    <>
      <Navbar />
      <div className='hero-bg'>
        <div className="hero-container">
          <div className="hero-text">
            {videoList.length > 0 && (
                <>
                  <h3>{videoList[0].altname}</h3>
                  <h4>{videoList[0].description}</h4>
                </>
            )}
          </div>
          <div className="hero-video">
            {videoList.length > 0 && (
                <iframe
                    width="560"
                    height="315"
                    src={videoList[0].url}
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; fullscreen; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowFullScreen
                />
            )}
          </div>

        </div>
      </div>
      <div className="transition-gradient">

      </div>
      <section className="all-videos-container">
        <div id="video_section" className="video-title">
          <h2>ALL VIDEOS</h2>
        </div>
        <div className="search">
          <input
              placeholder="Search for anything"
              onChange={(event) => setQuery(event.target.value)}
              value={query}
          />
        </div>
        <div className="video-gallery">
        <div className="video-ind">
          {videoList.length > 0 && (
            <>
              <iframe
                width="672"
                height="378"
                src={videoList[index].url}
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              />
              <h2>{videoList[index].name}</h2>
              <h3>{videoList[index].description}</h3>
            </>
          )}
        </div>

        <div className="vertical-menu">
          <div className="desktop-menu">{listAll()}</div>
          <div className="mobile-menu">
            <select class="select" onChange={handleSelectChange} value={index}>
              {videoList.map((video, i) => (
                <option key={i} value={i}>
                  {video.name}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
      </section>
<section className="about">
        <div id="about"></div>
        <h3>IDOTS IN GAMES. is a longstanding tradition which started all the way back in 2015. For 10 years the group has watched these videos as a reminder of better times.</h3>
        <a href="#top">Back to top of page</a>
        <h3>Copyright © IDIOTS IN GAMES 2025</h3>
      </section>

    </>
  )
}
